import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        author
        twitterUsername
      }
    }
    logoImg: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(height: 320, width: 450) {
          src
        }
      }
    }
  }
`

const SEO = ({ title, description, featuredImgUrl = "" }) => {
  const { site, logoImg } = useStaticQuery(getData)

  const { siteDesc, siteTitle, twitterUsername } = site.siteMetadata

  let ogImageUrl = ""
  if (featuredImgUrl) ogImageUrl = featuredImgUrl
  else ogImageUrl = logoImg.childImageSharp.fixed.src

  title = `${title} | ${siteTitle}`

  if (description) description = description.substring(0, 50)
  else description = siteDesc

  return (
    <Helmet htmlAttribute={{ lang: "en" }} title={title}>
      <meta name="description" content={description} />
      <meta name="image" content={ogImageUrl} />
      {/* Facebook Card */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image:secure_url" content={ogImageUrl} />
      <meta property="og:image" content={ogImageUrl} />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageUrl} />
    </Helmet>
  )
}

export default SEO
